export class Customer {
  customerId: number;
  customerName: string;
  columnSN: string;
  columnPN: string;
  columnRL: string;
  columnCustomer: string;
  columnServiceable: string;
  columnPORO: string;
  forceReferenceGeneration: boolean;
  batchMandatoryItems: BatchFormConfig;
  location: string;
  customersToSend: Customer[];
  managedByBBj: boolean;
  tiresCreationEnabled: boolean;
  operatorCreationEnabled: boolean;

  constructor(c: Customer) {
    this.customerId = c.customerId;
    this.columnSN = c.columnSN;
    this.columnPN = c.columnPN;
    this.columnRL = c.columnRL;
    this.columnCustomer = c.columnCustomer;
    this.columnServiceable = c.columnServiceable;
    this.columnPORO = c.columnPORO;
    this.forceReferenceGeneration = c.forceReferenceGeneration;
    this.batchMandatoryItems = c.batchMandatoryItems;
    this.location = c.location;
    this.customersToSend = c.customersToSend || [];
    this.managedByBBj = c.managedByBBj;
    this.tiresCreationEnabled = c.tiresCreationEnabled;
    this.operatorCreationEnabled = c.operatorCreationEnabled;
  }
}

interface BatchFormConfig {
  transporter: boolean;
  container: boolean;
  cmrQuantity: boolean;
  licensePlate: boolean;
  remark: boolean;
}
