import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Customer} from '../models/customers.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerInfoService {
  private _currentCustomer$: BehaviorSubject<Customer> = new BehaviorSubject(
    undefined
  );

  private serviceUrl: string = environment.baseUrl + '/customers/customer';

  constructor(private http: HttpClient) {
  }

  public fetchCustomerInfo(): Observable<Customer> {
    return this.http
      .get<Customer>(this.serviceUrl + '/getCustomerInfo')
      .pipe(
        tap((customer) => {
          this._currentCustomer$.next(new Customer(customer));
        }),
        catchError(() => {
          console.error('Loading customer data failed');
          return of(undefined);
        })
      );
  }

  // ------------------------------------------ ACCESSORS --------------------------------------------------- //

  public get currentCustomer$(): Observable<Customer> {
    return this._currentCustomer$.asObservable();
  }
}
