import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {EasytrackHttpParams} from '../models/easytrack-http-params.model';
import {PaginationItem} from '../models/pagination-item.model';
import {UserAction} from '../models/user-action.model';
import {User} from '../models/user.model';
import {MessageHandlerService} from './messageHandler.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
  };

  private _users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  private _currentUser$: BehaviorSubject<User | undefined> =
    new BehaviorSubject<User | undefined>(undefined);

  private _userActionsPagination$: Subject<PaginationItem<UserAction>> =
    new Subject<PaginationItem<UserAction>>();

  private serviceUrl: string = environment.baseUrl + '/users';

  constructor(
    private http: HttpClient,
    private messageService: MessageHandlerService
  ) {
  }

  // --------------------------------------------- HTTP FUNCTIONS ---------------------------------------------------------- //

  public getAllUsers(params?: EasytrackHttpParams): Observable<User[]> {
    return this.http
      .get<User[]>(this.serviceUrl, {params: {...params}})
      .pipe(
        tap((users: User[]) => {
          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User[]>(`Get users`))
      );
  }

  public getOneUser(userId: number): Observable<User> {
    return this.http.get<User>(this.serviceUrl + '/' + userId).pipe(
      catchError(this.messageService.handleError<User>('LOGS.USERS.'))
    );
  }

  public getMe(): Observable<User> {
    return this.http.get(this.serviceUrl + '/me').pipe(
      tap((user: User) => {
        this._currentUser$.next(user);
      }),
      catchError(this.messageService.handleError<User>(`Get me`))
    );
  }

  public createUser(body: CreateOperatorBody): Observable<User> {
    return this.http.post<User>(this.serviceUrl, body).pipe(
      catchError(this.messageService.handleError<User>(`Create user`))
    );
  }

  public updateUserStatus(
    id: number,
    username: string,
    enabled: boolean
  ): Observable<User> {
    return this.http
      .put<User>(this.serviceUrl + '/' + id + '/status', enabled)
      .pipe(
        tap(() => {
          this.messageService.log(
            'validation',
            `status changed for ${username}`,
            1,
            true
          );
          const users = this._users$.getValue();
          users.forEach((u) => {
            if (u.userId == id) {
              u.active = !enabled;
            }
          });

          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User>(`Toggle user`))
      );
  }

  public updateUserPassword(
    id: number,
    username: string,
    password: any
  ): Observable<User> {
    const body: any = {
      type: 'password',
      value: password,
      temporary: true,
    };

    return this.http
      .put<User>(this.serviceUrl + '/' + id + '/password', body)
      .pipe(
        tap(() =>
          this.messageService.log(
            'validation',
            `password changed for ${username}`,
            1,
            true
          )
        ),
        catchError(this.messageService.handleError<User>('Reset user password'))
      );
  }

  public getUserActions(
    userId: number,
    params?: EasytrackHttpParams
  ): Observable<PaginationItem<UserAction>> {
    return this.http
      .get<PaginationItem<UserAction>>(
        this.serviceUrl + '/' + userId + '/actions',
        {params: {...params}}
      )
      .pipe(
        tap((item: PaginationItem<UserAction>) => {
          this._userActionsPagination$.next(item);
        }),
        catchError((e) => {
          return of(undefined);
        })
      );
  }

  public updateUserTyresTableConfig(body: any) {
    return this.http.post<any>(this.serviceUrl + '/customTable', body).pipe(
      tap(() => {
        const user = this._currentUser$.getValue();
        user.tyreFrontEndCustomTable = {
          ...user.tyreFrontEndCustomTable,
          ...body,
        };
        this._currentUser$.next(user);
      }),
      catchError(this.messageService.handleError<User>(`Update table config`))
    );
  }

  public updateUserBatchesTableConfig(body: any) {
    return this.http
      .post<any>(this.serviceUrl + '/batchCustomTable', body)
      .pipe(
        tap(() => {
          const user = this._currentUser$.getValue();
          user.batchCustomTable = {
            ...user.batchCustomTable,
            ...body,
          };
          this._currentUser$.next(user);
        }),
        catchError(this.messageService.handleError<User>(`Update table config`))
      );
  }

  public updateUserTyresInBatchTableConfig(body: any) {
    return this.http
      .post<any>(this.serviceUrl + '/tyreInBatchCustomTable', body)
      .pipe(
        tap(() => {
          const user = this._currentUser$.getValue();
          user.tyreFrontEndBatchCustomTable = {
            ...user.tyreFrontEndBatchCustomTable,
            ...body,
          };
          this._currentUser$.next(user);
        }),
        catchError(this.messageService.handleError<User>(`Update table config`))
      );
  }

  // --------------------------------------------- OLD ---------------------------------------------------------- //

  /** GET: get the list of users */
  getUsers(): Observable<User[]> {
    return this.http
      .get<User[]>(this.serviceUrl + '/customerAdmin/getUsers')
      .pipe(
        tap((users) => {
          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User[]>(`getUsers`))
      );
  }

  /** PUT: enable/disable the user from the server */
  changeUserStatus(
    userName: string,
    userId: number,
    active: boolean
  ): Observable<User> {
    return this.http
      .put<User>(
        this.serviceUrl +
        '/customerAdmin/changeUserStatus/' +
        userId +
        '/' +
        !active,
        this.httpOptions
      )
      .pipe(
        tap((_) => {
          this.messageService.log(
            'validation',
            `status changed for ${userName}`,
            1,
            true
          );
          const users = this._users$.getValue();
          users.forEach((u) => {
            if (u.userId == userId) {
              u.active = !active;
            }
          });

          this._users$.next(users);
        }),
        catchError(this.messageService.handleError<User>('change user status'))
      );
  }

  /** PUT: reset user password */
  resetPassword(
    userName: string,
    userId: number,
    temporaryPassword: string
  ): Observable<User> {
    return this.http
      .put<User>(
        this.serviceUrl + '/customerAdmin/resetPassword/' + userId,
        {
          type: 'password',
          value: temporaryPassword,
          temporary: true,
        },
        this.httpOptions
      )
      .pipe(
        tap(() =>
          this.messageService.log(
            'validation',
            `password changed for ${userName}`,
            1,
            true
          )
        ),
        catchError(this.messageService.handleError<User>('reset user password'))
      );
  }

  // --------------------------------------------- ACCESSORS ---------------------------------------------------------- //

  public get currentUser$(): Observable<User> {
    return this._currentUser$.asObservable();
  }


  public get userActionsPagination$(): Observable<PaginationItem<UserAction>> {
    return this._userActionsPagination$.asObservable();
  }
}

export interface CreateOperatorBody {
}
